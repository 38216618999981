import claims from '@/helpers/ClaimsHelper'

export default [
  {
    name: 'reports-audit',
    path: '/reports/audit-log',
    component: () => import('@/pages/reporting/AuditLogListComponent.vue')
  },
  {
    name: 'reports-compliance',
    path: '/reports/compliance',
    component: () => import('@/pages/reporting/ComplianceComponent.vue')
  },
  {
    name: 'reports-privacy',
    path: '/reports/privacy',
    component: () => import('@/pages/reporting/PrivacyComponent.vue')
  },
  {
    name: 'reports-analytics',
    path: '/reports/analytics',
    component: () => import('@/pages/reporting/AnalyticsComponent.vue')
  },
  {
    name: 'reports-landing',
    path: '/reports',
    component: () => import('@/pages/reporting/CustomReportingComponent.vue'),
    props: true, // allows sending vue component properties via $router.push using params. e.g. { name: 'the route', params: { componentPropName: 'value' }}
    meta: {
      claims: claims.Admin
    }
  },
  {
    name: 'reports-platform-logs',
    path: '/reports/upload-status',
    component: () => import('@/pages/reporting/PlatformLogsComponent.vue')
  },
  {
    name: 'reports-gathered-data',
    path: '/reports/agent-logs',
    component: () => import('@/pages/reporting/GatheredDataComponent.vue'),
    meta: {
      claims: claims.AgentsAndEndpoints
    }
  },
  {
    name: 'data-export-wizard',
    path: '/data-export/wizard',
    component: () => import('@/pages/reporting/dataExport/DataExportWizardRoot.vue'),
    meta: {
      layout: 'fullscreen',
      claims: claims.Admin
    }
  },
  {
    name: 'data-export-edit',
    path: '/data-export/wizard/:id',
    component: () => import('@/pages/reporting/dataExport/DataExportWizardRoot.vue'),
    meta: {
      layout: 'fullscreen',
      claims: claims.Admin
    }
  }
]
