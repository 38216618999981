import dayjs from 'dayjs';
import StringHelper from '@/helpers/StringHelper';
import { FilterItemValueGroup } from '@/helpers/FilterClasses';
import TypeHelper from '@/helpers/TypeHelper';
import ArrayHelper from '@/helpers/ArrayHelper';
import { IFieldOrder, UIHelper } from '@/helpers/UtilityClasses';

export interface IGridFilter
{
  filter?: FilterItemValueGroup;
  filterConfigurationKey?: string;
  columnFilters?: any;
  gridConfigurationKey?: string;
}

export interface IDataRequestParams {
  order?: IFieldOrder[];
  groups?: IFieldOrder[];
  start?: number;
  limit?: number;
}

type GridColumnAlignment = 'left' | 'right' | 'center';

export class GridColumn {
  public text: string;

  public value: string;

  public align?: GridColumnAlignment;

  public sortable?: boolean;

  public width: any;

  public filterable: boolean;

  public minResizeWidth: number = null;

  constructor(
    text: string,
    value: string,
    align: GridColumnAlignment = 'left',
    sortable: boolean = true,
    width: any = null,
    filterable: boolean = true
) {
    this.text = text;
    this.value = value;
    this.align = align;
    this.sortable = sortable;
    this.width = width;
    this.filterable = filterable;
  }
}

export class DateColumn extends GridColumn
{
  public sort: (a: Date, b: Date) => number;

  /**
  * @description wrapper for vuetify grid date columns to handle date sorting and filtering.
  * @param {string} text display for the grid header
  * @param {string} value source data property name
  * @param {boolean} filterable enable filtering
  * @param {any} width defined column width.  E.g. '300px' or any other acceptable vuetify width value
  */
  constructor(text: string, value: string, filterable: boolean = false, width: any = null, sortable: boolean = true)
  {
    super(text, value, 'left', true, width);
    this.filterable = filterable;
    this.sort = DateColumn.compareDates;
    this.sortable = sortable;
  }

  private static compareDates(a: any, b: any): number {
    // a,b can be a string or date depending on the mapped property in the grid. Parse to convert it to a number for sorting comparisons
    const parseValue = (value: any): number => {
      if (!value) {
        return 0;
      }
      const numVal = Date.parse(value);
      return isNaN(numVal) ? 0 : numVal;
    };

    const aNum = parseValue(a);
    const bNum = parseValue(b);
    return aNum === bNum ? 0 : aNum > bNum ? 1 : -1;
  }

  private static toDateDisplay(d: Date, convertToLocal: boolean, formatter: string) {
    if (TypeHelper.isNull(d)) {
      return '';
    }
    // Note: this has been tested with random strings like 'test' and 'testz' and they do not cause pages to fail. The date columns just show 'Invalid Date'
    const originalDate = new Date(d);
    try {
      // To ensure proper conversion all dates passed to this method (whether a string or Date) must be formatted as 'YYYY-MM-DDThh:mm:ssZ'. E.g. UIHelper.formatDate handles this
      if (TypeHelper.isString(d)) {
        // We have some APIs that return '+00:00' on the end of the date strings. This is invalid and causes invalid conversions.
        if (d.toString().endsWith('+00:00')) {
          d = d.toString().replace('+00:00', '') as any;
        }

        if (!d.toString().toLowerCase().endsWith('z')) {
          d = UIHelper.formatDate(d, true);
        }
      }
      else {
        d = UIHelper.formatDate(d, true);
      }

      return convertToLocal ? dayjs(d).local().format(formatter) : dayjs(d).utc().format(formatter);
    }
    catch (err) {
      return dayjs(originalDate).format(formatter);
    }
  }

  public static toDisplayString(d: Date, showSeconds: boolean = true, convertToLocal: boolean = true): string
  {
    return this.toDateDisplay(d, convertToLocal, `MM/DD/YYYY HH:mm${(showSeconds ? ':ss' : '')}`);
  }

  public static toDateString(d: Date, convertToLocal: boolean = true): string
  {
    return this.toDateDisplay(d, convertToLocal, 'MM/DD/YYYY');
  }

  public static toTimeString(d: Date, showSeconds: boolean = true, convertToLocal: boolean = true): string
  {
    return this.toDateDisplay(d, convertToLocal, `HH:mm${(showSeconds ? ':ss' : '')}`);
  }
}

export class GridOptions
{
  public page: number = 1;

  public itemsPerPage: number = 100;

  public sortBy: string[] = [];

  public sortDesc: boolean[] = [];

  public groupBy: string[] = [];

  public groupDesc: boolean[] = [];

  public multiSort: boolean = false;

  public mustSort: boolean = false;
}

export class GridTableComponent
{
  /*
    This Control should be used with @/components/common/DataTableComponent.vue

    For examples of using this control along with the data table component see:
    1. ScanLandingComponent.vue -> Example for grids that load all data from the API in one call (E.g. paging and sorting is local)
    2. SearchResults.vue, AuditLogListComponent.vue -> Examples for grids that sync paging and sorting with the server.
 */

  public static get minColResizeWidthDefault(): number { return 50; }

  public static get maxColumnResizeInitializedWidthDefault(): number { return 400; }

  private basePageOptions: number[] = [10, 50, 100, 500];

  public items: Array<any>;

  public itemKey: string;

  public headers: Array<any>;

  public fixedHeader: boolean = true;

  public height: number = 450; // fits about 8 rows

  public footerProps = {};

  public pageSizeOptions: number[] = [];

  // Holds selected / checked rows
  public selected: Array<any>;

  // Show select all check box in header when showSelect is true. Note that select all only selects items on the current page
  public singleSelect: boolean;

  // Show check boxes
  public showSelect: boolean;

  // Bind search text box to this for real time searching. Must call server to search if using server side paging.
  public search: string = '';

  public options: GridOptions;

  public totalItems: number = 0;

  public resizingInitialized: boolean = false;

  public minColumnResizeWidth: number = GridTableComponent.minColResizeWidthDefault;

  public maxColumnResizeInitializedWidth: number = GridTableComponent.maxColumnResizeInitializedWidthDefault;

  public noDataText: string = 'No data available';

  // For all grid APIs the sort field names sent to the API must have the first char upper cased excluding only user defined custom exports
  public upperCaseSortFieldsForServerSort: boolean = true;

  // include all rows option should only be used for client side only grids as server paging does not support all rows requests.
  constructor(itemKey: string, includeAllRowsOption: boolean = false)
  {
    this.itemKey = itemKey;
    this.headers = [];
    this.items = [];
    this.selected = [];
    this.singleSelect = false;
    this.showSelect = false;
    this.options = new GridOptions();
    this.pageSizeOptions = !includeAllRowsOption
      ? this.basePageOptions
      : this.basePageOptions.concat([-1]);

    this.footerProps = {
      'items-per-page-options': this.pageSizeOptions//,
      // TODO: Possibly add these options once all grids are updated to use this control.  Get PM approval first.
      //'show-current-page': true, // shows page number between the page nav buttons '<' and '>'
      //'show-first-last-page': true // shows buttons to navigate to the first or last page
    };
  }

  private static renameForServerSort(field: string): string {
    // field names must start with upper case for server sorting to match C# property names
    if (field.length === 1) {
      return field.toUpperCase(); // just in case but we should never have a single character field name
    }
    return field.substring(0, 1).toUpperCase() + field.substring(1);
  }

  public createRequestParams(): IDataRequestParams
  {
    const fieldOrder: IFieldOrder[] = [];

    if (this.options.sortBy.length && this.options.sortDesc.length)
    {
      // only single sort allowed so no need to loop the arrays used below
      fieldOrder.push(<IFieldOrder>{
        field: this.upperCaseSortFieldsForServerSort ? GridTableComponent.renameForServerSort(this.options.sortBy[0]) : this.options.sortBy[0],
        ascending: !this.options.sortDesc[0]
      });
    }

    return <IDataRequestParams>{
      order: fieldOrder,
      groups: [],
      start: this.options.itemsPerPage * (this.options.page - 1),
      limit: this.options.itemsPerPage
    }
  }
}

export class GridMoreItem {
  id: any

  label: string

  disabled: boolean

  constructor(id: any, label: string, disabled: boolean = false) {
    this.id = id
    this.label = label
    this.disabled = disabled
  }
}

// internal class. should not be exported
class ColumnResizeHandler {
  private headerElement: HTMLTableCellElement;

  private currentHeaderWidth: number = null;

  private currentX: number = null;

  public resizeHandlerDiv: HTMLDivElement = null;

  private minWidth: number;

  private maxWidth: number;

  private readonly grabberWidth: number = 8;

  constructor(headerObject: GridColumn, headerElement: HTMLTableHeaderCellElement, minColWidth: number, maxColWidth: number) {
    if (!headerObject || !headerElement) {
      return;
    }

    this.minWidth = TypeHelper.isNull(minColWidth) || minColWidth < 1 ? GridTableComponent.minColResizeWidthDefault : minColWidth;
    if (!TypeHelper.isNull(headerObject.minResizeWidth)) {
      this.minWidth = headerObject.minResizeWidth;
    }
    this.maxWidth = TypeHelper.isNull(maxColWidth) ? GridTableComponent.maxColumnResizeInitializedWidthDefault : maxColWidth;
    this.headerElement = headerElement;

    const offsetWidth = headerElement.offsetWidth - this.grabberWidth;
    const calculatedWidth = offsetWidth > this.maxWidth ? this.maxWidth : offsetWidth;
    // TODO: Possibly add a property to GridColumn class to flag a column as an ellipses or icon.
    // empty header text should be an image or ellipses menu column.  80px shold be sufficient
    const widthToUse = StringHelper.isNullOrWhiteSpace(headerObject.text) ? 50 : calculatedWidth;
    // Note: The grid header object width must be set to a defined pixel width or the vuetify grid will resize the column when sorting occurs
    headerObject.width = `${widthToUse}px`;
    // workaround for the check box header.  vuetify automatically sets the width to 1px so if we get a column with 1px width we adjust to to 50px;
    if (this.headerElement && this.headerElement.style.width === '1px') {
      this.headerElement.style.minWidth = '50px';
      this.headerElement.style.width = '50px';
    }
    this.resizeHandlerDiv = this.createResizeHandleDiv();
  }

  private createResizeHandleDiv = (): HTMLDivElement => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.bottom = '0';
    div.style.right = '2px';
    //div.style.backgroundColor = 'rgba(0,0,0, 0.12)'; // useful for testing to see the grabbable div
    div.style.width = `${this.grabberWidth}px`; // allows a decent size grab area
    div.style.cursor = 'col-resize';
    div.style.userSelect = 'none';
    div.style.height = '80%';
    div.className = 'data-table-resize-handle'; // class defined in _tables.scss. shows divider on hover
    return div;
  }

  public initialize() {
    if (!this.headerElement || !this.resizeHandlerDiv) {
      return;
    }

    // Add Event Handlers
    this.resizeHandlerDiv.addEventListener('click', (e) => {
      // stops grid from sorting when the user clicks to grab the resize div
      e.stopPropagation();
    });

    const that = this;

    const mouseMoveHandler = (e) => {
      const diff = e.clientX - that.currentX;
      const calculatedWidth = that.currentHeaderWidth + diff;
      const newWidth = `${calculatedWidth > that.minWidth ? calculatedWidth : that.minWidth}px`;
      // must set both width and minWidth for the resize to work
      that.headerElement.style.width = newWidth;
      that.headerElement.style.minWidth = newWidth;
    };

    const mouseUpHandler = () => {
      that.currentX = null;

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    this.resizeHandlerDiv.addEventListener('mousedown', (e) => {
      that.currentHeaderWidth = that.headerElement.offsetWidth;
      that.currentX = e.clientX;

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    });

    this.headerElement.appendChild(this.resizeHandlerDiv);
  }
}

export abstract class GridColumnsResizeUtility {
  private static readonly resizableClassName = 'data-table-resizable';

  private static readonly resizableSelectableClassName = 'data-table-resizable-selectable';

  private static readonly resizableStickyMenuClassName = 'data-table-resizable-stickymenu';

  public static toggleResizableSelectableTableClasses(vDataTableId: string, hasCheckbox: boolean) {
    const tableDiv: HTMLElement = document.getElementById(vDataTableId);
    if (!tableDiv) {
      return;
    }

    const tableElementCollection = tableDiv.getElementsByTagName('table');
    if (!tableElementCollection || !tableElementCollection.length) {
      return;
    }
    const tableElement = tableElementCollection[0];
    if (!tableElement) {
      return;
    }

    const classList = tableElement.classList;
    if (!classList) {
      return;
    }

    if (hasCheckbox && !classList.contains(GridColumnsResizeUtility.resizableSelectableClassName)) {
      classList.add(GridColumnsResizeUtility.resizableSelectableClassName);
    }
    else if (!hasCheckbox && classList.contains(GridColumnsResizeUtility.resizableSelectableClassName)) {
      classList.remove(GridColumnsResizeUtility.resizableSelectableClassName);
    }
  }

  public static initializeTable(vDataTableId: string, gridControl: GridTableComponent) {
    if (StringHelper.isNullOrWhiteSpace(vDataTableId) || !gridControl || gridControl.resizingInitialized || !ArrayHelper.isArraySet(gridControl.headers)) {
      return;
    }

    const tableDiv: HTMLElement = document.getElementById(vDataTableId);
    if (!tableDiv) {
      return;
    }

    const tableElementCollection = tableDiv.getElementsByTagName('table');
    if (!tableElementCollection || !tableElementCollection.length) {
      return;
    }
    const tableElement = tableElementCollection[0];
    const headerElements = tableDiv.getElementsByTagName('th');

    if (!tableElement || !headerElements || !headerElements.length) {
      return;
    }

    (headerElements as any).forEach((headerElement) => {
      // all headers must have a set width or vuetify will resize them when sorting.
      // TODO: This works but we need to research a better way to find the correct header object that matches the header element
      const header = gridControl.headers.find(h => !h.dpmResizeInitialized && h.text === headerElement.innerText);
      if (!header) {
        return; // continues the loop
      }

      headerElement.style.whiteSpace = 'normal';
      headerElement.style.padding = '0 8px';
      headerElement.style.overflow = 'hidden';
      headerElement.style.lineHeight = 1;

      const handler = new ColumnResizeHandler(header,
        headerElement,
        gridControl.minColumnResizeWidth,
        gridControl.maxColumnResizeInitializedWidth);

      // Flag covers the case where multiple columns have the same header text.  In that case the first header is processed multiple times and the others do not get initialized.
      header.dpmResizeInitialized = true;
      handler.initialize();
    });

    // resizable columns requires a fixed layout, set via data-table-resizable class. The width must also change from the default 100% width to a defined width
    tableElement.width = `${tableElement.offsetWidth}px;`;
    tableElement.classList.add(GridColumnsResizeUtility.resizableClassName);
    if (gridControl.showSelect) {
      tableElement.classList.add(GridColumnsResizeUtility.resizableSelectableClassName);
    }
    // data-table-resizable-stickymenu is defined in _tables.scss and sets ellipsis menu / more column stickiness when it is the last column in the grid
    if (ArrayHelper.isArraySet(gridControl.headers) && ['more', 'actions'].includes(gridControl.headers[gridControl.headers.length - 1].value)) {
      tableElement.classList.add(GridColumnsResizeUtility.resizableStickyMenuClassName);
    }

    gridControl.resizingInitialized = true;
  }
}
