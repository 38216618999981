import claims from '@/helpers/ClaimsHelper'

export default [
  {
    name: 'playbooks',
    path: '/scans/scan-playbooks',
    component: () => import('@/pages/playbook/PlaybookListComponent.vue'),
    meta: {
      claims: claims.ViewPlaybooksList
    }
  },
  {
    name: 'playbook-builder',
    path: '/playbooks/builder',
    component: () => import('@/pages/playbook/PageSearchPlaybookBuilder.vue'),
    meta: {
      layout: 'fullscreen',
      claims: claims.CreatePlaybook
    }
  },
  {
    name: 'playbook-edit',
    path: '/playbooks/builder/:playbookId',
    component: () => import('@/pages/playbook/PageSearchPlaybookBuilder.vue'),
    meta: {
      layout: 'fullscreen',
      claims: claims.EditPlaybook
    }
  },
  {
    name: 'playbook-executor',
    path: '/playbooks/executor/:playbookId',
    component: () => import('@/pages/playbook/PageSearchPlaybookExecutor.vue'),
    meta: {
      layout: 'fullscreen',
      // Note: run scan claim is required by the task scheduler API RunPlaybookResult which is used when executing manual playbook actions.
      claims: [claims.PlaybookExecutor, claims.RunScan],
      requireAllClaims: true
    },
  },
  {
    name: 'custom-scripts',
    path: '/settings/script-repository',
    component: () => import('@/pages/remediation/ScriptListComponent.vue'),
    meta: {
      claims: claims.CustomScriptREAD
    }
  },
]
